export default {
    set: (name, value) => localStorage.setItem(name, value),
    get: (name) => localStorage.getItem(name),
    clear: (name) => localStorage.removeItem(name),

    setCookie: (name, value) => {
        let expires = ''
        const date = new Date()
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
        expires = `; expires=${date.toUTCString()}`
        document.cookie = `${name}=${value || ''}${expires}; path=/`
    },

    getCookie: (name) => {
        const nameEQ = `${name}=`
        const ca = document.cookie.split(';')
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
        }
        return null
    },

    eraseCookie: (name) => {
        document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    }

}
