import api from '@configs/Api/Api'

const baseEndpoint = 'user-me'
const baseEndpoint2 = 'users'

export default {

  updatePassword(data) {
    return api.put('user/change-password', data)
  },
  getUserProfile() {
    return api.get(baseEndpoint)
  },
  getUsers(params) {
    return api.get(baseEndpoint2, { ...params })
  },
  getUser(id) {
    return api.get(`${baseEndpoint2}/${id}`)
  },
  createUser(params) {
    return api.post(`${baseEndpoint2}`, { ...params })
  },
  updateUser(id, params) {
    return api.put(`${baseEndpoint2}/${id}`, { ...params })
  },
  recoverPassword(params) {
    return api.post(`recover-password`, { ...params })
  },
  resetPassword(hash, params) {
    return api.post(`recover-password/${hash}`, { ...params })
  }
}
