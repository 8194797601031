// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

export const INITIAL_STATE = {
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  toggleLoading: false
}

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MENU_COLLAPSED':
      return {...state, value: {...action.value ? action.value : null}}
    case 'CONTENT_WIDTH':
      return {...state, value: {...action.value ? action.value : null}}
    case 'MENU_HIDDEN':
      return {...state, value: {...action.value ? action.value : null}}
    case 'TOGGLE_LOADING':
      return {...state, value: {...action.value ? action.value : false}}
    default:
      return state
  }
}

export default reducer
