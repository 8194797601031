export default [
  {
    locale: 'en',
    name: 'english'
  },
  {
    locale: 'es',
    name: 'spanish'
  },
  {
    locale: 'fr',
    name: 'french'
  },
  {
    locale: 'de',
    name: 'german'
  },
  {
    locale: 'fi',
    name: 'finnish'
  },
  {
    locale: 'et',
    name: 'estonian'
  }
]
