const INITIAL_STATE = {
  fbConnected: {venueId: null, status: null}
}

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FB_CONNECTED':
      return {...state, fbConnected: {...action.payload}}
    default:
      return state
  }
}
