export default [
  {
    locale: 'en',
    name: 'english'
  },
  {
    locale: 'es',
    name: 'spanish'
  }
]
