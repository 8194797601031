import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { withReduxStateSync } from 'redux-state-sync'

import { reducer as User } from './User'
import { reducer as Layout } from './Layout'
import { reducer as Venue } from './Venue'

export default (history) => withReduxStateSync(combineReducers({
  router: connectRouter(history),
  user: User,
  layout: Layout,
  venue: Venue
}))
