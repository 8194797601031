import _ from 'lodash'

export const formatDataToSend = (formData, form) => {
    const dataToSend = {}
    _.map(_.keys(form.getState().modified), (key) => {
        if (_.get(formData[key], 'id')) {
            _.set(dataToSend, key, _.get(formData, `${key}.id`))
        } else if (formData.id) {
            if (_.isArray(formData[key]) && _.get(formData, `${key}.0.value`) && _.get(formData, `${key}.0.label`)) {
                _.set(dataToSend, key, _.map(formData[key], 'value'))
            } else if (_.isObject(formData[key]) && formData[key].value && formData[key].label) {
                _.set(dataToSend, key, formData[key].value)
            } else {
                _.set(dataToSend, key, _.get(formData, key, ''))
            }
        } else if (_.isArray(formData[key]) && _.get(formData, `${key}.0.value`) && _.get(formData, `${key}.0.label`)) {
            _.set(dataToSend, key, _.map(formData[key], 'value'))
        } else if (_.isObject(formData[key]) && formData[key].value && formData[key].label) {
            _.set(dataToSend, key, formData[key].value)
        } else {
            _.set(dataToSend, key, _.get(formData, key))
        }
    })

    return dataToSend
}

export const formatFormDataBody = (data) => {
    const formData = new FormData()

    _.forEach(data, (value, key) => {
        if (value) formData.append(key, value)
    })

    return formData
}
